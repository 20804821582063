function breadcrumb() {

	if (jQuery('.dynamic-service-menu').length === 0) {
		return
	}

	const breadcrumbContainer = jQuery('.breadcrumb-container');
	const articleWrapper = jQuery('.article-wrapper');
	const serviceMenuElement = '<ul class="service-menu"></ul>';
	let noArticleServiceMenu;
	let noBreadcrumbServiceMenu;
	let breadcrumbServiceMenu = jQuery(breadcrumbContainer).find('.service-menu')[0];
	let articleServiceMenu = jQuery(articleWrapper).find('.service-menu')[0];

	const initialBreadcrumbServiceMenuItems = jQuery(breadcrumbServiceMenu).find(
		'.service-menu__item'
	);
	const initialArticleServiceMenuItems = jQuery(articleServiceMenu).find(
		'.service-menu__item'
	);

	// used to insert a DOM element in relation to another element
	const insertElement = (position, relationalElement, element) => {
		switch (position) {
			case 'before':
				jQuery(element).insertBefore(jQuery(relationalElement));
				break;
			case 'after':
				jQuery(element).insertAfter(jQuery(relationalElement));
				break;
			case 'append':
				jQuery(relationalElement).append(element);
				break;
			case 'prepend':
				jQuery(relationalElement).prepend(element);
				break;
		}
	};


	// Creates a breadcrumb service menu if it does not exist
	const createBreadcumbServiceMenu = () => {
		noBreadcrumbServiceMenu =
			jQuery(breadcrumbContainer).find('.service-menu')[0] === undefined;

		if (noBreadcrumbServiceMenu) {
			insertElement('append', '.breadcrumb-container', serviceMenuElement);

			// Change variable to newly added element
			breadcrumbServiceMenu = jQuery(breadcrumbContainer).find('.service-menu')[0];
		}
	};

	// Creates an article service menu if it does not exist
	const createArticleServiceMenu = () => {
		noArticleServiceMenu =
			jQuery(articleWrapper).find('.service-menu')[0] === undefined;

		if (noArticleServiceMenu) {
			insertElement('append', '.service-menu-container', serviceMenuElement);

			// Change variable to newly added element
			articleServiceMenu = jQuery(articleWrapper).find('.service-menu')[0];
		}
	};

	const moveBreadCrumb = () => {
		//if tablet or mobile
		if (window.matchMedia('(max-width: 767px)').matches) {
			createArticleServiceMenu();

			// Gets the new service menu items of the article
			const currentArticleServiceMenuItem = jQuery(articleServiceMenu).find(
				'.service-menu__item'
			);

			// If the service menu items length is unchanged, add the initial
			// breadcrumb items and remove the empty list
			if (
				currentArticleServiceMenuItem.length ===
				initialArticleServiceMenuItems.length
			) {
				jQuery(articleServiceMenu).append(jQuery(initialBreadcrumbServiceMenuItems));
				jQuery(breadcrumbServiceMenu).remove();
			}
		//if desktop
		} else {
			createBreadcumbServiceMenu();

			// Gets the new service menu items of the breadcrumb
			const currentBreadcrumbServiceMenuItem = jQuery(breadcrumbServiceMenu).find(
				'.service-menu__item'
			);

			// If the service menu list is empty, add the initial breadcrumb items
			// and remove service menu list, if it was initial empty
			if (currentBreadcrumbServiceMenuItem.length === 0) {
				//remove breadcrumb if init breadcrumb length is 0
				if(jQuery(initialBreadcrumbServiceMenuItems.length === 0)){
					jQuery(breadcrumbServiceMenu).remove()
				} else {
					jQuery(breadcrumbServiceMenu).append(jQuery(initialBreadcrumbServiceMenuItems));
				}
				//jQuery(breadcrumbServiceMenu).append(jQuery(initialBreadcrumbServiceMenuItems));

				if (initialArticleServiceMenuItems.length === 0) {
					jQuery(articleServiceMenu).remove();
				}
			}
		}
	};

	moveBreadCrumb();

	jQuery(window).resize(function () {
		moveBreadCrumb();
	});
}

export default breadcrumb;
